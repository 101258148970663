import {useEffect, useState} from 'react';
import TaskMultiplePromotionIntercom from '../ws/TaskMultiplePromotionIntercom';

type BulkPromoteIntercomsData = {
    fieldDefaultValue: number,
    showPromoteModal: boolean,
    handleClosePromoteModal: () => void,
    isLoading: boolean,
    selectedBrandId: number,
    selectedTechnologyId: number,
    setSelectedTechnologyId: (id: number) => void,
    setIsLoading: (loading: boolean) => void,
    showConfirmModal: boolean,
    handleBulkPromote: () => void,
    handleCloseConfirmModal: () => void,
    setSelectedBrandId: (id: number) => void,
    handleCancelPromoteModal: () => void,
}

export const useBulkPromoteIntercoms = (
    showPromoteBulkIntercomsModal: boolean,
    setShowPromoteBulkIntercomsModal: (show: boolean) => void,
): BulkPromoteIntercomsData => {
    const fieldDefaultValue = -1;
    const [showPromoteModal, setShowPromoteModal] = useState<boolean>(false);
    const [selectedBrandId, setSelectedBrandId] = useState<number>(fieldDefaultValue);
    const [selectedTechnologyId, setSelectedTechnologyId] = useState<number>(fieldDefaultValue);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

    useEffect(() => {
        if (showPromoteBulkIntercomsModal) {
            setShowPromoteModal(true);
        }
    }, [showPromoteBulkIntercomsModal])

    const handleClosePromoteModal = () => {
        setShowPromoteModal(false);
        setShowConfirmModal(true);
    }

    const handleCancelPromoteModal = () => {
        setShowPromoteModal(false)
        setShowPromoteBulkIntercomsModal(false)
    }

    const handleCloseConfirmModal = () => {
        setSelectedBrandId(fieldDefaultValue);
        setSelectedTechnologyId(fieldDefaultValue);
        setShowConfirmModal(false);
        setShowPromoteBulkIntercomsModal(false);
    }

    const handleBulkPromote = () => {
        setIsLoading(true);
        new TaskMultiplePromotionIntercom({brandId: selectedBrandId, technologyId: selectedTechnologyId})
            .onSuccess(() => {
                window.location.reload();
            })
            .onFail(() => setIsLoading(false))
            .execute();
    }

    return {
        fieldDefaultValue,
        showPromoteModal,
        handleClosePromoteModal,
        isLoading,
        selectedBrandId,
        selectedTechnologyId,
        setSelectedTechnologyId,
        setIsLoading,
        showConfirmModal,
        handleBulkPromote,
        handleCloseConfirmModal,
        setSelectedBrandId,
        handleCancelPromoteModal
    }
}