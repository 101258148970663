import {BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from "@cuatroochenta/co-generic-request";

export interface TaskImportCheckRunningProcessResponse {
    success: boolean,
    code: string
}
export default class TaskImportCheckRunningProcess extends BaseGenericRequest<{}, TaskImportCheckRunningProcessResponse> {

    public constructor() {
        super(METHOD.METHOD_GET, getUrlEndpointPrivate('intercom/wsimport/check-running-process'));
    }
}