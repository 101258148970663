import React, {useEffect, useState} from 'react';
import {formatDate} from '@cuatroochenta/co-admin-react-library';
import './importValidationMessagesScreen.scss';
import {ExcelValidationMessage} from '../../../domain/ExcelValidationMessage';
import {ValidationMessageCard} from '../../../components/validationMessage/ValidationMessageCard';
import {FileCard} from '../../../components/fileCard/FileCard';

interface IProps {
    messages: ExcelValidationMessage[],
    file: File,
    handleDeleteImportFile: () => void,
}

export const ImportValidationMessagesScreen: React.FC<IProps> = (
    {
        messages,
        file,
        handleDeleteImportFile,
    }
): React.ReactElement => {
    const [allMessages, setAllMessages] = useState<ExcelValidationMessage[]>([]);

    useEffect(() => {
        setAllMessages([...allMessages, ...messages]);
    }, [messages])

    const currentDateTime = new Date();
    const fileDate = formatDate(currentDateTime);
    const fileTime = currentDateTime.toLocaleTimeString();

    return (
        <>
            <FileCard
                fileName={file.name}
                fileDate={`${fileDate} a las ${fileTime}`}
                canDeleteFile={true}
                handleDeleteImportFile={handleDeleteImportFile}
            />
            <div className={"validate-scroll-container"}>
                <div className={"validate-list"}>
                    {
                        allMessages.map((message: ExcelValidationMessage, key: number) => {
                            return (
                                <ValidationMessageCard
                                    key={key}
                                    message={message}
                                    isFirstCard={key === 0}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}