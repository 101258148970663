import React from 'react';
import {
    Icon,
    Message,
} from '@cuatroochenta/co-admin-react-library';
interface IProps {
    total: number,
    compatible: number,
}

export const CountResultMessage = (
    {
        total,
        compatible,
    }: IProps
): React.ReactElement => {

    return (
        <Message
            title={<><Icon fontAwesomeClass="fa-solid fa-circle-info"/><span className={"ml-3"}>Importante</span></>}
            content={
                <div className={"bulk-promote-content"}>
                    <div><b>{`Se han encontrado un total de ${total} dispositivos.`}</b></div>
                    <div className={"mt-1"}>
                        <Icon fontAwesomeClass={"fa-solid fa-circle-check mr-3 promoted"}/>
                        Seleccionados para promocionar: <b>{compatible} dispositivos</b>
                    </div>
                    <div className={"mt-1"}>
                        <Icon fontAwesomeClass={"fa-solid fa-circle-xmark mr-3 not-promoted"}/>
                        No compatible para promocionar: <b>{total - compatible} dispositivos</b>
                    </div>
                    <div className={"mt-3"}>La cantidad de intercoms seleccionados para promocionar puede ser menor que
                        el número total
                        de intercoms debido a sus limitaciones técnicas, como la posbile incompatibilidad del
                        ICAD.
                    </div>
                </div>
            }
        />
    )
}