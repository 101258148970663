import {FieldFValue} from '@cuatroochenta/co-admin-react-library';
import {useEffect, useState} from 'react';
import TaskBrandList, {TaskBrandListResponse} from '../ws/TaskBrandList';
import TaskTechnologyList, {TaskTechnologyListResponse} from '../ws/TaskTechnologyList';
import TaskMultiplePromotionCountIntercom, {
    TaskMultiplePromotionCountIntercomResponse
} from '../ws/TaskMultiplePromotionCountIntercom';

type BulkPromotionListData = {
    handleAcceptPromoteModal: () => void,
    disabledAcceptBulkPromote: boolean,
    brandSelectorFieldValues: FieldFValue[],
    technologySelectorFieldValues: FieldFValue[],
    handleSelectedTechnologyId: (id: number) => void,
    handleSelectedBrandId: (id: number) => void,
    countResult: { total: number, compatible: number },
    showCountResult: boolean,
}

export const useBulkPromotionModal = (
    fieldDefaultValue: number,
    showModal: boolean,
    closeHandler: () => void,
    isLoading: boolean,
    selectedBrandId: number,
    selectedBrandIdHandler: (id: number) => void,
    selectedTechnologyId: number,
    selectedTechnologyIdHandler: (id: number) => void,
    isLoadingHandler: (loading: boolean) => void,
    cancelHandler: () => void,
): BulkPromotionListData => {
    const [showCountResult, setShowCountResult] = useState<boolean>(false);
    const [disabledAcceptBulkPromote, setDisabledAcceptBulkPromote] = useState<boolean>(true);
    const [countResult, setCountResult] = useState<{total: number, compatible: number}>({total: 0, compatible: 0});
    const [brandSelectorFieldValues, setBrandSelectorFieldValues] = useState<FieldFValue[]>([]);
    const [technologySelectorFieldValues, setTechnologySelectorFieldValues] = useState<FieldFValue[]>([]);

    useEffect(() => {
        if (showModal) {
            selectedBrandIdHandler(fieldDefaultValue);
            selectedTechnologyIdHandler(fieldDefaultValue);
            setShowCountResult(false);
            setDisabledAcceptBulkPromote(true);
            brandList();
            technologyList();
        }
    }, [showModal])


    const brandList = () => {
        isLoadingHandler(true);
        new TaskBrandList({maxPerPage: 1000, order: 'ASC', orderBy: 'Brand.name'})
            .onSuccess((data: TaskTechnologyListResponse) => {
                const brandOptions = data.results.map(result => ({id: result.oid, description: result.name}));
                setBrandSelectorFieldValues(brandOptions);
                isLoadingHandler(false);
            })
            .onFail(() => isLoadingHandler(false))
            .execute();
    }

    const technologyList = () => {
        isLoadingHandler(true);
        new TaskTechnologyList({maxPerPage: 1000, order: 'ASC', orderBy: 'Technology.name'})
            .onSuccess((data: TaskBrandListResponse) => {
                const technologyOptions = data.results.map(result => ({id: result.oid, description: result.name}));
                setTechnologySelectorFieldValues(technologyOptions);
                isLoadingHandler(false);
            })
            .onFail(() => isLoadingHandler(false))
            .execute();
    }

    const handleAcceptPromoteModal = () => {
        if (!showCountResult) {
            bulkPromoteCount(selectedBrandId, selectedTechnologyId)
        } else {
            acceptToBulkPromote();
        }
    }

    const bulkPromoteCount = (brandId: number, technologyId: number) => {
        isLoadingHandler(true);
        new TaskMultiplePromotionCountIntercom({brandId, technologyId})
            .onSuccess((data: TaskMultiplePromotionCountIntercomResponse) => {
                setShowCountResult(true);
                setCountResult(data);
                isLoadingHandler(false);
                setDisabledAcceptBulkPromote(data.compatible === 0)
            })
            .onFail(() => isLoadingHandler(false))
            .execute();
    }

    const acceptToBulkPromote = () => {
        setShowCountResult(false);
        closeHandler();
    }

    const handleSelectedBrandId = (id: number) => {
        setShowCountResult(false);
        selectedBrandIdHandler(id);
        const enabled = !!((id && id !== fieldDefaultValue) || (selectedTechnologyId && selectedTechnologyId !== fieldDefaultValue));
        setDisabledAcceptBulkPromote(!enabled);
    }

    const handleSelectedTechnologyId = (id: number) => {
        setShowCountResult(false);
        selectedTechnologyIdHandler(id);
        const enabled = !!((id && id !== fieldDefaultValue) || (selectedBrandId && selectedBrandId !== fieldDefaultValue))
        setDisabledAcceptBulkPromote(!enabled);
    }

    return {
        handleAcceptPromoteModal,
        disabledAcceptBulkPromote,
        brandSelectorFieldValues,
        technologySelectorFieldValues,
        handleSelectedTechnologyId,
        handleSelectedBrandId,
        showCountResult,
        countResult,
    }
}
