import {
    Alert,
    AlertType,
    Button,
    ButtonClasses,
    Dropzone,
    Modal,
    ModalProps,
    withAppStyle,
    WithAppStyleProps,
} from '@cuatroochenta/co-admin-react-library';
import React, {useEffect, useState} from 'react';
import {ImportIntercomImportantMessage} from './components/ImportIntercomImportantMessage';
import './importIntercomsModal.scss';
import {LastRegisters} from './components/lastRegisters/LastRegisters';
import {ExcelVersionHistoryList} from './components/excelVersionHistory/ExcelVersionHistoryList';
import {useImportFile} from './hooks/useImportFile';
import {ImportValidationMessagesScreen} from './components/importValidationMessages/ImportValidationMessagesScreen';
import {EventMessage} from '../domain/EventMessage';
import {ExcelValidationMessage} from '../domain/ExcelValidationMessage';
import {ProgressBarCard} from '../components/progressBar/ProgressBarCard';
import {useExcelHistory} from './hooks/useExcelHistory';
import {useExcelCurrentVersion} from './hooks/useExcelCurrentVersion';

interface IProps {
    showModal: boolean,
    closeHandler: () => void,
    isLoading: boolean,
    isLoadingHandler: (loading: boolean) => void,
    disabledRestore: boolean,
}

const enum importSteps {
    DROP_FILE = 'dropFile',
    VALIDATION_FILE = 'validationFile',
    IMPORTING_FILE = 'importingFile',
    FINISHED_IMPORT_FILE = 'finishedImportFile',
    HISTORY_RESTORE = 'historyRestore',
}

const ImportIntercomsModal = (
    {
        showModal,
        closeHandler,
        isLoading,
        isLoadingHandler,
        appStyles,
        disabledRestore,
    }: IProps & WithAppStyleProps
): React.ReactElement => {
    const [currentStep, setCurrentStep] = useState<importSteps>(importSteps.DROP_FILE);

    const {
        currentVersion,
        exportHandler,
    } = useExcelCurrentVersion(
        showModal,
        isLoadingHandler
    );
    const {
        lastRegistersList,
        excelVersionHistoryRestore,
        registersList,
        showRegistersHandler,
        showRegistersList,
        allRegisters,
        currentPageRegistersList,
        setCurrentPageRegistersList,
        dataTransferRestore,
        isLoadingRegistersList,
        backAllRegistersHandler,
        currentFileNameRestore,
        setCurrentFileNameRestore,
    } = useExcelHistory(
        showModal,
        isLoadingHandler
    );
    const {
        isLoading: isImportValidateLoading,
        existValidationError,
        showAlertError,
        alertErrorMessage,
        setMessages,
        messages,
        importHandler,
        dataTransferUpdate,
        importFile,
        setImportFile,
        handleDropAcceptedTask,
    } = useImportFile(
        isLoadingHandler
    );

    useEffect(() => {
        if (currentStep === importSteps.DROP_FILE) {
            setMessages([]);
        }
    }, [currentStep]);

    useEffect(() => {
        if (dataTransferUpdate === 100 && !isImportValidateLoading) {
            setCurrentStep(importSteps.FINISHED_IMPORT_FILE);
        }
    }, [dataTransferUpdate]);

    useEffect(() => {
        if (importFile && showAlertError) {
            setImportFile(null);
            setCurrentStep(importSteps.DROP_FILE);
        }
    }, [showAlertError])

    useEffect(() => {
        if (!showAlertError && importFile && !isImportValidateLoading) {
            setCurrentStep(importSteps.VALIDATION_FILE);
        }
    }, [isImportValidateLoading]);

    const handleImport = () => {
        importHandler();
        setCurrentStep(importSteps.IMPORTING_FILE);
    };

    const handleDeleteImportFile = () => {
        setImportFile(null);
        setCurrentStep(importSteps.DROP_FILE);
    };

    const handleExcelVersionHistoryRestore = () => {
        excelVersionHistoryRestore();
        setCurrentStep(importSteps.HISTORY_RESTORE);
    };

    const renderExcelVersionMessage = () => {
        return (
            <div className={"text-container"}>
                <div>
                    Debes importar un excel con la misma versión que la actual. La versión actual es
                    <span className={"fw-bold"} style={{color: appStyles.primaryColor}}>
                        {` ${currentVersion}`}
                    </span>
                </div>
                <Button content={'Descargar version actual'} onClick={exportHandler}/>
            </div>
        )
    };

    const renderImportContent = () => {
        return (
            <>
                {renderExcelVersionMessage()}
                <Alert
                    show={showAlertError}
                    message={alertErrorMessage}
                    type={AlertType.DANGER}
                />
                <div className={"dropzone-container"}>
                    <Dropzone
                        onDropAcceptedTask={handleDropAcceptedTask}
                        extensions={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                        maxFiles={1}
                        isLoadingFile={isImportValidateLoading}
                        dropMessage={'Sube un documento de hoja de cálculo o arrástralo y suéltalo aquí'}
                    />
                </div>
                <div className={"bottom-container"}>
                    <div className={"left-content"}>
                        <LastRegisters
                            lastRegisters={lastRegistersList}
                            excelVersionHistoryRestore={handleExcelVersionHistoryRestore}
                            showRegistersHandler={showRegistersHandler}
                            isLoading={isLoadingRegistersList}
                            currentFileNameRestoreHandler={setCurrentFileNameRestore}
                            disabledRestore={disabledRestore}
                        />
                    </div>
                    <div className={"right-content"}>
                        <ImportIntercomImportantMessage/>
                    </div>
                </div>
            </>
        )
    };

    const renderExcelVersionHistoryList = () => {
        return (
            <ExcelVersionHistoryList
                registers={registersList}
                excelVersionHistoryRestore={handleExcelVersionHistoryRestore}
                currentPageRegistersList={currentPageRegistersList}
                currentPageRegistersListHandler={setCurrentPageRegistersList}
                excelIntercomsVersionHistoryList={allRegisters}
                infiniteScroll={true}
                isLoading={isLoadingRegistersList}
                currentFileNameRestoreHandler={setCurrentFileNameRestore}
                disabledRestore={disabledRestore}
            />
        )
    };

    const renderIntercomRestoreLoader = () => {
        const isFinished = dataTransferRestore === 100 && !isLoading;
        const progressBarMessage = isFinished ? 'Restaurazación finalizada' : 'Restauración en curso...';
        const eventMessageSuccess = new ExcelValidationMessage(EventMessage.SUCCESS, 'Restauración realizada con éxito');
        return (
            <ProgressBarCard
                message={'Se está realizando la restauración. Este proceso puede tardar varios minutos...'}
                fileName={currentFileNameRestore}
                progress={dataTransferRestore}
                progressBarMessage={progressBarMessage}
                isFinishedProgress={isFinished}
                successMessage={eventMessageSuccess}
            />
        )
    };

    const renderImportLoader = () => {
        const finishedImport = currentStep === importSteps.FINISHED_IMPORT_FILE;
        const progressBarMessage = finishedImport ? 'Importación finalizada' : 'Importación en curso...';
        const eventMessageSuccess = new ExcelValidationMessage(EventMessage.SUCCESS, 'Importación realizada con éxito');
        if (!importFile) {
            return <></>
        }
        return (
            <ProgressBarCard
                message={'Se está realizando la importación. Este proceso puede tardar varios minutos...'}
                fileName={importFile.name}
                progress={dataTransferUpdate}
                progressBarMessage={progressBarMessage}
                isFinishedProgress={finishedImport}
                successMessage={eventMessageSuccess}
            />
        )
    };

    const renderImportValidationMessagesScreen = () => {
        if (!importFile) {
            return <></>
        }
        return (
            <>
                {renderExcelVersionMessage()}
                <ImportValidationMessagesScreen
                    messages={messages}
                    file={importFile}
                    handleDeleteImportFile={handleDeleteImportFile}
                />
            </>
        )
    };

    const importModalProps: ModalProps = {
        title: 'Importar intercoms',
        acceptText: 'Importar',
        acceptHandler: handleImport,
        showCloseButton: true,
        children: currentStep === importSteps.VALIDATION_FILE ? renderImportValidationMessagesScreen() : renderImportContent(),
        onClose: closeHandler,
        disabledAcceptHandler: !importFile || isImportValidateLoading || existValidationError
    } as ModalProps;

    const excelVersionHistoryModalProps: ModalProps = {
        title: 'Historial de registros',
        children: renderExcelVersionHistoryList(),
        showBackButton: true,
        showFooter: false,
        onBack: backAllRegistersHandler,
    } as ModalProps;

    let intercomRestoreLoaderModalProps: ModalProps = {
        title: 'Restaurar copia anterior',
        showCloseButton: true,
        children: renderIntercomRestoreLoader(),
        showCancelButton: false,
        onClose: closeHandler,
    } as ModalProps;
    if (currentStep === importSteps.HISTORY_RESTORE) {
        intercomRestoreLoaderModalProps = {
            ...intercomRestoreLoaderModalProps,
            acceptText: 'Cerrar',
            acceptHandler: closeHandler,
            disabledAcceptHandler: false
        }
    }

    let importLoaderModalProps: ModalProps = {
        title: 'Importar intercoms',
        showCloseButton: true,
        children: renderImportLoader(),
        showCancelButton: false,
        onClose: closeHandler,
    } as ModalProps;
    if (currentStep === importSteps.FINISHED_IMPORT_FILE) {
        importLoaderModalProps = {
            ...importLoaderModalProps,
            acceptText: 'Cerrar',
            acceptHandler: closeHandler,
            disabledAcceptHandler: false
        }
    }

    let modalProps: ModalProps = importModalProps;
    if (showRegistersList) {
        modalProps = excelVersionHistoryModalProps;
    }
    if (currentStep === importSteps.IMPORTING_FILE || currentStep === importSteps.FINISHED_IMPORT_FILE) {
        modalProps = importLoaderModalProps;
    }
    if (currentStep === importSteps.HISTORY_RESTORE) {
        modalProps = intercomRestoreLoaderModalProps;
    }

    const commonModalProps = {
        // @ts-ignore
        show: showModal,
        className: "import-intercoms-modal",
        fullScreen: true,
        isLoading: isLoading,
        buttonAcceptClass: ButtonClasses.CUSTOM_BUTTON_FILLED,
        buttonCancelClass: ButtonClasses.CUSTOM_BUTTON_BORDER,
        ...modalProps,
    };

    return <Modal {...commonModalProps}/>

}

export default withAppStyle(ImportIntercomsModal)