import {useState} from 'react';
import TaskIntercomValidate from '../ws/TaskIntercomValidate';
import {ExcelValidationMessage} from '../../domain/ExcelValidationMessage';
import TaskIntercomImport from '../ws/TaskIntercomImport';

type ImportFileData = {
    isLoading: boolean,
    existValidationError: boolean,
    showAlertError: boolean,
    alertErrorMessage: string,
    setMessages: (message: ExcelValidationMessage[]) => void,
    messages: ExcelValidationMessage[],
    importHandler: () => void,
    dataTransferUpdate: number,
    importFile: File | null,
    setImportFile: (file: File | null) => void,
    handleDropAcceptedTask: (files: File[]) => void,
}

export const useImportFile = (
    isLoadingHandler: (loading: boolean) => void,
): ImportFileData => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [existValidationError, setExistValidationError] = useState<boolean>(false);
    const [showAlertError, setShowAlertError] = useState<boolean>(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>('');
    const [messages, setMessages] = useState<ExcelValidationMessage[]>([]);
    const [dataTransferUpdate, setDataTransferUpdate] = useState<number>(0.01);
    const [importFile, setImportFile] = useState<File | null>(null);

    const messagesHandler = (newMessage: any): void => setMessages([...messages, newMessage]);

    const handleFindValidationError = () => {
        if (!existValidationError) {
            setExistValidationError(true);
        }
    }

    const handleAlertError = (message: string) => {
        setAlertErrorMessage(message);
        setShowAlertError(true);
    }

    const taskImportValidate = (file: File) => {
        setAlertErrorMessage('');
        setShowAlertError(false);
        setIsLoading(true);
        const request = {file, setIsLoading, messagesHandler, handleFindValidationError, handleAlertError};
        new TaskIntercomValidate(request).execute();
    }

    const importHandler = () => {
        isLoadingHandler(true);
        new TaskIntercomImport({setIsLoading: isLoadingHandler, setDataTransferUpdate}).execute();
    }

    const handleDropAcceptedTask = (files: File[]) => {
        setImportFile(files[0]);
        taskImportValidate(files[0]);
    };

    return {
        isLoading,
        existValidationError,
        showAlertError,
        alertErrorMessage,
        setMessages,
        messages,
        importHandler,
        dataTransferUpdate,
        importFile,
        setImportFile,
        handleDropAcceptedTask,
    }
}