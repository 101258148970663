import {BaseGenericRequest, getUrlEndpointPrivate, AuthManager} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';

interface TaskIntercomVersionHistoryRestoreRequest {
    setIsLoading: (loading: boolean) => void,
    setDataTransfer: (progress: number) => void,
}

export default class TaskIntercomVersionHistoryRestore extends BaseGenericRequest<TaskIntercomVersionHistoryRestoreRequest, null> {

    public constructor(request: TaskIntercomVersionHistoryRestoreRequest) {
        const endPoint = getUrlEndpointPrivate('intercom/excelintercomsversionhistory/restore');
        super(METHOD.METHOD_POST, endPoint);
        this.httpRequest.open(METHOD.METHOD_POST, endPoint);
        this.httpRequest.setRequestHeader('Authorization', `Bearer ${AuthManager.getAuthToken()}`)
        let lastProgress = 0;
        this.httpRequest.onprogress = function (event) {
            request.setIsLoading(false);
            // @ts-ignore
            const response = event?.currentTarget?.response;
            const regex = /{([^}]*)}/g;
            const dataArray = response.match(regex);
            if (dataArray && dataArray.length !== 0) {
                setProgress(dataArray)
            }
        }

        const setProgress = (dataArray: string[]) => {
            dataArray.forEach((data: any) => {
                const toJSON = JSON.parse(data);
                const progress = toJSON.progress;
                if (progress > lastProgress) {
                    lastProgress = progress;
                    request.setDataTransfer(progress);
                }
            })
        }
    }
}
