import {BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';

export interface TaskExcelCurrentVersionResponse {
    version: number,
    name: string
}
export default class TaskExcelCurrentVersion extends BaseGenericRequest<{}, TaskExcelCurrentVersionResponse> {

    public constructor() {
        super(METHOD.METHOD_GET, getUrlEndpointPrivate('intercom/version'));
    }
}