import {AuthManager, BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';

interface TaskIntercomImportRequest {
    setIsLoading: (loading: boolean) => void,
    setDataTransferUpdate: (progress: number) => void,
}

export default class TaskIntercomImport extends BaseGenericRequest<TaskIntercomImportRequest, {}> {

    public constructor(request: TaskIntercomImportRequest) {
        const endPoint = getUrlEndpointPrivate('intercom/wsimport');
        super(METHOD.METHOD_POST, endPoint);
        this.httpRequest.open(METHOD.METHOD_POST, endPoint);
        this.httpRequest.setRequestHeader('Authorization', `Bearer ${AuthManager.getAuthToken()}`);
        this.httpRequest.onprogress = function (event) {
            request.setIsLoading(false);
            // @ts-ignore
            const response = event?.currentTarget?.response;
            const regex = /{([^}]*)}/g;
            const dataArray = response.match(regex);
            if (dataArray && dataArray.length !== 0) {
                dataArray.forEach((data: string) => {
                    const toJSON = JSON.parse(data);
                    if (toJSON.progress > 0) {
                        request.setDataTransferUpdate(toJSON.progress);
                    }
                })
            }
        }
    }
}