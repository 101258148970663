import React from 'react';
import {BulkPromotionConfirmationModal} from './BulkPromotionConfirmationModal';
import {BulkPromotionModal} from './BulkPromotionModal';
import {useBulkPromoteIntercoms} from './hooks/useBulkPromoteIntercoms';

interface IProps {
    showPromoteBulkIntercomsModal: boolean,
    setShowPromoteBulkIntercomsModal: (show: boolean) => void,
}

export const BulkPromoteIntercoms = (
    {
        showPromoteBulkIntercomsModal,
        setShowPromoteBulkIntercomsModal,
    }: IProps
): React.ReactElement => {
    const {
        fieldDefaultValue,
        showPromoteModal,
        handleClosePromoteModal,
        isLoading,
        selectedBrandId,
        selectedTechnologyId,
        setSelectedTechnologyId,
        setIsLoading,
        showConfirmModal,
        handleBulkPromote,
        handleCloseConfirmModal,
        setSelectedBrandId,
        handleCancelPromoteModal,
    } = useBulkPromoteIntercoms(showPromoteBulkIntercomsModal, setShowPromoteBulkIntercomsModal);

    return (
        <>
            <BulkPromotionModal
                fieldDefaultValue={fieldDefaultValue}
                showModal={showPromoteModal}
                closeHandler={handleClosePromoteModal}
                isLoading={isLoading}
                selectedBrandId={selectedBrandId}
                selectedBrandIdHandler={setSelectedBrandId}
                selectedTechnologyId={selectedTechnologyId}
                selectedTechnologyIdHandler={setSelectedTechnologyId}
                isLoadingHandler={setIsLoading}
                cancelHandler={handleCancelPromoteModal}
            />
            <BulkPromotionConfirmationModal
                showModal={showConfirmModal}
                handleBulkPromote={handleBulkPromote}
                closeHandler={handleCloseConfirmModal}
                isLoading={isLoading}
            />
        </>
    )
}