import React from 'react';
import {Icon, IconsBase} from '@cuatroochenta/co-admin-react-library';
import './fileCard.scss';

interface IProps {
    fileName: string,
    fileDate?: string,
    canDeleteFile?: boolean,
    children?: React.ReactElement,
    handleDeleteImportFile?: () => void,
}

export const FileCard = (
    {
        fileName,
        fileDate,
        children,
        canDeleteFile,
        handleDeleteImportFile
    }: IProps
): React.ReactElement => {

    return (
        <div className={'file-card'}>
            <div className={"file-card-content"}>
                <div className={"icon-file"}>
                    <Icon fontAwesomeClass={"fa-regular fa-file-lines"}/>
                </div>
                <div className={"content"}>
                    <div className={"file-name"}>
                        {fileName}
                    </div>
                    {
                        fileDate &&
                        <div className={"file-time"}>
                            {fileDate}
                        </div>
                    }
                </div>
                {
                    canDeleteFile &&
                    <div className={"icon-delete"}>
                        <Icon fontAwesomeClass={IconsBase.DELETE} onClick={handleDeleteImportFile}/>
                    </div>
                }
            </div>
            {children}
        </div>
    )
}