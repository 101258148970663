import { BaseGenericRequest, getUrlEndpointPrivate } from "@cuatroochenta/co-admin-react-library";
import {METHOD} from "@cuatroochenta/co-generic-request";

interface TaskDeleteIntercomsResponse {
    success: boolean,
    message: string
}

export default class TaskDeleteIntercom extends BaseGenericRequest<{}, TaskDeleteIntercomsResponse> {

    private readonly data;

    public constructor(data: any) {
        super(METHOD.METHOD_DELETE, getUrlEndpointPrivate('intercom/' + data.oid + '/delete'));
        this.data = data;
    }

    protected getRequest(): {} {
        return this.data;
    }
}