import {
    BaseGenericRequest,
    getUrlEndpointPrivate,
} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';

export interface TaskBrandListRequest {
    filters?: any,
    orderBy?: any,
    order?: 'ASC' | 'DESC',
    maxPerPage?: number,
}

export interface TaskBrandListResponse {
    pages: number,
    results: any[],
    total: number,
}

export default class TaskBrandList extends BaseGenericRequest<{}, TaskBrandListResponse> {

    private readonly data;

    public constructor(data: TaskBrandListRequest) {
        super(METHOD.METHOD_POST, getUrlEndpointPrivate('brand/list/extended/1'));
        this.data = data;
    }

    protected getRequest(): TaskBrandListRequest {
        return this.data
    }
}