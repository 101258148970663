import React from 'react';
import {LoadingView, Modal} from '@cuatroochenta/co-admin-react-library';

interface IProps {
    showModal: boolean,
    handleBulkPromote: () => void,
    closeHandler: () => void,
    isLoading: boolean,
}

export const BulkPromotionConfirmationModal = (
    {
        showModal,
        handleBulkPromote,
        closeHandler,
        isLoading,
    }: IProps
): React.ReactElement => {

    return (
        <Modal
            show={showModal}
            title={'Promocionar intercoms'}
            acceptHandler={handleBulkPromote}
            acceptText={'Promocionar'}
            onClose={closeHandler}
        >
            <>
                <LoadingView loading={isLoading}/>
                <div>
                    <div>Estás a punto de añadir dispositivos a la base de datos curada. Por favor, asegúrate de que la
                        información es correcta antes de continuar.
                    </div>
                    <div className={"mt-3"}>¿Estás seguro de querer promocionar estos intercom?</div>
                </div>
            </>
        </Modal>
    )
}