import {EventMessage} from "./EventMessage";

export class ExcelValidationMessage {

    private readonly _event: EventMessage;
    private readonly _message: string;

    public constructor(
        event: EventMessage,
        message: string
    ) {
        this._event = event;
        this._message = message;
    }

    get event(): EventMessage {
        return this._event;
    }

    get message(): string {
        return this._message;
    }
}