import {AuthManager, BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';
import {ExcelValidationMessage} from '../../domain/ExcelValidationMessage';
import {EventMessage} from '../../domain/EventMessage';

interface TaskIntercomValidateRequest {
    file: File,
    setIsLoading: (loading: boolean) => void,
    messagesHandler: (messages: any) => void,
    handleFindValidationError: () => void,
    handleAlertError: (message: string) => void,
}

export default class TaskIntercomValidate extends BaseGenericRequest<FormData, {}> {

    private readonly file: File;

    public constructor(request: TaskIntercomValidateRequest) {
        const endPoint = getUrlEndpointPrivate('intercom/wsimport/validate');
        super(METHOD.METHOD_POST, endPoint);
        this.file = request.file;
        this.httpRequest.open(METHOD.METHOD_POST, endPoint);
        this.httpRequest.setRequestHeader('Authorization', `Bearer ${AuthManager.getAuthToken()}`);
        this.httpRequest.onprogress = function (event) {
            request.setIsLoading(false);
            // @ts-ignore
            const response = event?.currentTarget?.response;
            const errorPattern = /"success":false/;
            if (errorPattern.test(response)) {
                const toJSON = JSON.parse(response);
                request.handleAlertError(toJSON.error[0]);
                return;
            }
            const regex = /{([^}]*)}/g;
            const dataArray = response.match(regex);
            if (dataArray && dataArray.length !== 0) {
                setMessages(dataArray);
            }
        }

        const setMessages = (dataArray: string[]) => {
            dataArray.forEach(data => {
                const toJSON = JSON.parse(data);
                const toJSONEvent = toJSON.event;
                if (toJSON.message || toJSONEvent === 'Success') {
                    const excelValidationMessage = new ExcelValidationMessage(toJSONEvent, toJSON.message);
                    request.messagesHandler(excelValidationMessage);
                    if (toJSONEvent === EventMessage.ERROR) {
                        request.handleFindValidationError();
                    }
                }
            })
        }
    }

    protected getRequest(): FormData {
        const data: FormData = new FormData();
        data.append('intercom-excel-versioned-file', this.file);
        return data;
    }
}