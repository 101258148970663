import React, {useState} from 'react';
import TaskDeleteIntercom from "./ws/TaskDeleteIntercom";
import TaskUpdateStateIntercom, {STATE_INTERCOM} from "./promoteBulkIntercoms/ws/TaskUpdateStateIntercom";
import {ActionConfirmButton} from "./components/ActionConfirmButton";
import {LoadingView, ButtonClasses} from "@cuatroochenta/co-admin-react-library";

interface IProps {
    intercom: any
}

export const CustomButtonListEditScreen = (
    {
        intercom,
    }: IProps
): React.ReactElement => {
    const [isActionLoading, setIsActionLoading] = useState<any>(false);

    const updateStateIntercom = (type: STATE_INTERCOM) => {
        setIsActionLoading(true);
        new TaskUpdateStateIntercom({'oid': intercom.oid, 'type': type})
            .onSuccess(
                () => {
                    window.location.reload()
                }
            ).execute();
    }

    const deleteIntercom = () => {
        setIsActionLoading(true);
        new TaskDeleteIntercom({'oid': intercom.oid})
            .onSuccess(() => {
                    window.location.href = "/intercom/list";
                }
            ).execute();
    }

    const renderActionButtons = (): React.ReactElement | null => {
        switch (intercom.promotingState) {
            case 'NOT_PROMOTED':
                return (
                    <>
                        <ActionConfirmButton
                            title={"Eliminar"}
                            message={"¿Está seguro que desea eliminar este intercom?"}
                            doActionOnConfirm={() => deleteIntercom()}
                            buttonClass={ButtonClasses.CUSTOM_BUTTON_BORDER}
                        />
                        <ActionConfirmButton
                            title={"Promocionar"}
                            message={"¿Está seguro que desea promocionar este intercom?"}
                            doActionOnConfirm={() => updateStateIntercom(STATE_INTERCOM.PROMOTE)}
                            disabled={!intercom.compatible}
                        />
                    </>
                );
            case 'PENDING_PROMOTE':
                break;
            case 'PROMOTED':
                return (
                    <ActionConfirmButton
                        title={"Eliminar promoción"}
                        message={"¿Está seguro que desea continuar con la eliminación de la promoción de este intercom?"}
                        doActionOnConfirm={() => updateStateIntercom(STATE_INTERCOM.DEMOTE)}
                    />
                );
            case 'PENDING_UPDATE':
                break;
            case 'PENDING_REMOVE':
                break;
        }

        return null;
    }

    return (
        <div className="content-state-action-intercom">
            <LoadingView loading={isActionLoading} />
            <div className="state-description" dangerouslySetInnerHTML={{__html: intercom.promotingStateDescription}} />
            { renderActionButtons() }
        </div>
    )
}
