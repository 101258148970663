import {BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';

export interface TaskTechnologyListRequest {
    filters?: any,
    orderBy?: any,
    order?: 'ASC' | 'DESC',
    maxPerPage?: number,
}

export interface TaskTechnologyListResponse {
    pages: number,
    results: any[],
    total: number,
}

export default class TaskTechnologyList extends BaseGenericRequest<{}, TaskTechnologyListResponse> {

    private readonly data;

    public constructor(data: TaskTechnologyListRequest) {
        super(METHOD.METHOD_POST, getUrlEndpointPrivate('technology/list/extended/1'));
        this.data = data;
    }

    protected getRequest(): TaskTechnologyListRequest {
        return this.data
    }
}