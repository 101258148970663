import React from 'react';
import './progressBarCard.scss';
import {FileCard} from '../fileCard/FileCard';
import {ValidationMessageCard} from '../validationMessage/ValidationMessageCard';
import {ExcelValidationMessage} from '../../domain/ExcelValidationMessage';

interface IProps {
    message: string,
    fileName: string,
    progress: number,
    progressBarMessage: string,
    isFinishedProgress: boolean,
    successMessage: ExcelValidationMessage,
}

export const ProgressBarCard: React.FC<IProps> = (
    {
        message,
        fileName,
        progress,
        progressBarMessage,
        isFinishedProgress,
        successMessage,
    }: IProps
): React.ReactElement => {
    return (
        <>
            <div className={"text-container"}>
                <div>
                    {message}
                </div>
            </div>
            {progress &&
                <FileCard
                    fileName={fileName}
                    children={(
                        <div className={"progress-bar-container"}>
                            <div>{progressBarMessage}</div>
                            <div className={"progress-bar"}>
                                <div className={"bar-loaded"} style={{width: `${progress}%`}}/>
                                <div className={"bar-loading"} style={{width: `${100 - progress}%`}}/>
                            </div>
                        </div>
                    )}
                />
            }
            {isFinishedProgress && <ValidationMessageCard message={successMessage}/>}
        </>
    )
}
