import React, {useEffect, useState} from 'react';
import {ExcelVersionHistory} from '../../../domain/ExcelVersionHistory';
import ExcelVersionHistoryCard from './ExcelVersionHistoryCard';
import './excelVersionHistoryList.scss';
import {InfiniteScroll, LoadingView} from '@cuatroochenta/co-admin-react-library';

interface IProps {
    registers: ExcelVersionHistory[],
    headerList?: React.ReactElement,
    excelVersionHistoryRestore: () => void,
    infiniteScroll?: boolean,
    currentPageRegistersList?: number,
    currentPageRegistersListHandler?: (currentPage: number) => void,
    excelIntercomsVersionHistoryList?: (currentPage: number) => void,
    isLoading: boolean,
    currentFileNameRestoreHandler: (fileName: string) => void,
    disabledRestore: boolean,
}

export const ExcelVersionHistoryList = (
    {
        registers,
        headerList,
        excelVersionHistoryRestore,
        currentPageRegistersList,
        currentPageRegistersListHandler,
        excelIntercomsVersionHistoryList,
        infiniteScroll = false,
        isLoading,
        currentFileNameRestoreHandler,
        disabledRestore,
    }: IProps
): React.ReactElement => {
    const [currentList, setList] = useState<ExcelVersionHistory[]>([]);

    useEffect(() => {
        if (registers && registers.length > 0) {
            setList([...currentList, ...registers])
        }
    }, [isLoading])

    const scrollEndHandler = () => {
        if (currentPageRegistersList && excelIntercomsVersionHistoryList && currentPageRegistersListHandler) {
            const nextPage = currentPageRegistersList + 1;
            excelIntercomsVersionHistoryList(nextPage);
            currentPageRegistersListHandler(nextPage);
        }
    }

    const renderContent = (
        <>
            <LoadingView loading={isLoading}/>
            {headerList}
            {currentList.map((register: ExcelVersionHistory, key: number) =>
                {
                    return (
                        <ExcelVersionHistoryCard
                            key={key}
                            excelVersionHistoryRestore={excelVersionHistoryRestore}
                            register={register}
                            currentFileNameRestoreHandler={currentFileNameRestoreHandler}
                            disabledRestore={disabledRestore}
                        />
                    )
                }
            )}
        </>
    )

    if (!infiniteScroll) {
        return (
            <div className={"registers-container"}>
                {renderContent}
            </div>
        )
    }

    return (
        <InfiniteScroll
            className={"registers-container"}
            scrollEndHandler={scrollEndHandler}
        >
            {renderContent}
        </InfiniteScroll>
    )
}