import React from 'react';
import {Button, ButtonClasses} from '@cuatroochenta/co-admin-react-library';
import './lastRegisters.scss';
import {ExcelVersionHistory} from '../../../domain/ExcelVersionHistory';
import {ExcelVersionHistoryList} from '../excelVersionHistory/ExcelVersionHistoryList';

interface IProps {
    lastRegisters: ExcelVersionHistory[],
    excelVersionHistoryRestore: () => void,
    showRegistersHandler: () => void,
    isLoading: boolean,
    currentFileNameRestoreHandler: (fileName: string) => void,
    disabledRestore: boolean,
}

export const LastRegisters = (
    {
        lastRegisters,
        excelVersionHistoryRestore,
        showRegistersHandler,
        isLoading,
        currentFileNameRestoreHandler,
        disabledRestore,
    }: IProps
): React.ReactElement => {

    const headerList = (
        <div className={"header"}>
            <div className={"title"}>Últimos registros</div>
            <div className={"button"}>
                <Button
                    buttonClass={ButtonClasses.CUSTOM_BUTTON_BASIC_PRIMARY}
                    content={'VER TODOS'}
                    onClick={showRegistersHandler}
                />
            </div>
        </div>
    );

    return (
        <ExcelVersionHistoryList
            registers={lastRegisters}
            excelVersionHistoryRestore={excelVersionHistoryRestore}
            headerList={headerList}
            isLoading={isLoading}
            currentFileNameRestoreHandler={currentFileNameRestoreHandler}
            disabledRestore={disabledRestore}
        />
    )
}