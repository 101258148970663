export class ExcelVersionHistory {

    private readonly _fileName: string;
    private readonly _url: string;
    private readonly _date: string;
    private readonly _userFullName: string;
    private readonly _canRestore: boolean;
    private readonly _canDownload: boolean;

    public constructor(
        fileName: string,
        url: string,
        date: string,
        userFullName: string,
        canRestore: boolean,
        canDownload: boolean
    ) {
        this._fileName = fileName;
        this._url = url;
        this._date = date;
        this._userFullName = date;
        this._canRestore = canRestore;
        this._canDownload = canDownload;
    }

    get fileName(): string {
        return this._fileName;
    }

    get url(): string {
        return this._url;
    }

    get date(): string {
        return this._date;
    }

    get userFullName(): string {
        return this._userFullName;
    }

    get canRestore(): boolean {
        return this._canRestore;
    }

    get canDownload(): boolean {
        return this._canDownload;
    }
}