import React, {useState} from 'react';
import {Icon, IconsBase, Modal, withAppStyle, WithAppStyleProps} from '@cuatroochenta/co-admin-react-library';
import {ExcelVersionHistory} from '../../../domain/ExcelVersionHistory';
import './excelVersionHistoryCard.scss';

interface IProps {
    register: ExcelVersionHistory,
    key: number,
    excelVersionHistoryRestore: () => void,
    currentFileNameRestoreHandler: (fileName: string) => void,
    disabledRestore: boolean,
}

const ExcelVersionHistoryCard: React.FC<IProps & WithAppStyleProps> = (
    {
        register,
        key,
        excelVersionHistoryRestore,
        appStyles,
        currentFileNameRestoreHandler,
        disabledRestore,
    }: IProps & WithAppStyleProps
): React.ReactElement => {
    const [showRestoreConfirmModal, setShowRestoreConfirmModal] = useState<boolean>(false);

    function renderExcelVersionHistoryRestore() {
        const handleAccept = () => {
            excelVersionHistoryRestore();
            currentFileNameRestoreHandler(register.fileName);
        }

        return (
            <Modal
                className={"restore-modal"}
                show={showRestoreConfirmModal}
                title={'Restaurar copia anterior'}
                acceptText={'Restaurar'}
                acceptHandler={handleAccept}
                onClose={() => setShowRestoreConfirmModal(false)}
                confirmationModal={true}
                children={<>¿Estás seguro de querer restaurar una copia anterior?</>}
            />
        )
    }

    return (
        <div key={key} className={"register-container"}>
            <div className={"icon-file"}>
                <div className={"icon"}>
                    <Icon fontAwesomeClass={"fa-regular fa-file-lines"}/>
                </div>
                <div className={"file"}>
                    {register.fileName}
                    <span className={"date"}>{register.date}</span>
                </div>
            </div>

            <div className={"name"}>
                {register.userFullName}
            </div>

            <div className={"actions-icons"} style={{color: appStyles.primaryColor}}>
                <Icon
                    fontAwesomeClass={"fa-solid fa-arrows-rotate"}
                    className={register.canRestore ? "" : "visibility-hidden"}
                    onClick={() => setShowRestoreConfirmModal(true)}
                    disabled={disabledRestore}
                />
                <Icon
                    fontAwesomeClass={IconsBase.DOWNLOAD}
                    className={register.canDownload ? "" : "visibility-hidden"}
                    onClick={() => window.open(register.url, '_blank')}
                />
            </div>
            {renderExcelVersionHistoryRestore()}
        </div>
    )
}

export default withAppStyle(ExcelVersionHistoryCard) as React.ComponentType<IProps>;