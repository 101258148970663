import React from "react";
import {Icon, Message} from "@cuatroochenta/co-admin-react-library";

export const ImportIntercomImportantMessage = (): React.ReactElement => {

    return (
        <>
            <Message
                title={<><Icon fontAwesomeClass="fa-solid fa-circle-info"/><span className={"ml-3"}>Importante</span></>}
                content={
                    <div className={"mt-3"}>
                        Debes importar un Excel con la misma versión que la actual. La última columna del Excel indica
                        el nombre del identificador del intercom en BBDD y no es modificable. Los intercoms nuevos deben
                        de tener dicha última columna vacía; será la importación quien asigne los IDs correspondientes.
                        Estos intercoms nuevos no necesitan ir al final del Excel, pueden insertarse en cualquier fila
                        teniendo en siempre en cuenta de dejar esa columna ID vacía. Se eliminarán todos aquellos intercoms
                        que no aparezcan al final del excel.
                    </div>
                }
            />
        </>
    );
}