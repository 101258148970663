import {useEffect, useState} from 'react';
import {ExcelVersionHistory} from '../../domain/ExcelVersionHistory';
import TaskExcelIntercomsVersionHistory, {
    TaskExcelIntercomsVersionHistoryResponse
} from "../ws/TaskExcelIntercomsVersionHistory";
import {ExcelVersionHistoryMapper} from "../dataMapper/ExcelVersionHistoryMapper";
import TaskIntercomVersionHistoryRestore from "../ws/TaskIntercomVersionHistoryRestore";

type ExcelHistoryData = {
    lastRegistersList: ExcelVersionHistory[],
    excelVersionHistoryRestore: () => void,
    registersList: ExcelVersionHistory[],
    showRegistersHandler: () => void,
    showRegistersList: boolean,
    allRegisters: (currentPage: number) => void,
    currentPageRegistersList: number,
    setCurrentPageRegistersList: (page: number) => void,
    isLoadingRegistersList: boolean,
    dataTransferRestore: number,
    backAllRegistersHandler: () => void,
    currentFileNameRestore: string,
    setCurrentFileNameRestore: (fileName: string) => void,
}

export const useExcelHistory = (
    showModal: boolean,
    isLoadingHandler: (loading: boolean) => void,
): ExcelHistoryData => {
    const [lastRegistersList, setLastRegistersList] = useState<ExcelVersionHistory[]>([]);
    const [registersList, setRegistersList] = useState<ExcelVersionHistory[]>([]);
    const [isLoadingRegistersList, setIsLoadingRegistersList] = useState<boolean>(false);
    const [currentPageRegistersList, setCurrentPageRegistersList] = useState<number>(1);
    const [showRegistersList, setShowRegisterList] = useState<boolean>(false);
    const [dataTransferRestore, setDataTransferRestore] = useState<number>(0.01);
    const [registersListTotalPages, setRegistersListTotalPages] = useState<number>(1);
    const [currentFileNameRestore, setCurrentFileNameRestore] = useState<string>('');

    useEffect(() => {
        if (showModal) {
            lastRegisters();
        }
    }, [showModal]);

    const excelIntercomsVersionHistoryList = (page: number, maxPerPage: number): ExcelVersionHistory[] | void => {
        if (page > registersListTotalPages) {
            return;
        }
        const responseToDomain: ExcelVersionHistory[] = [];
        setIsLoadingRegistersList(true);
        new TaskExcelIntercomsVersionHistory(page, maxPerPage)
            .onSuccess((data: TaskExcelIntercomsVersionHistoryResponse) => {
                setRegistersListTotalPages(data.pages);
                data.results.map(result => {
                    const toDomain = ExcelVersionHistoryMapper.toDomain(result)
                    responseToDomain.push(toDomain);
                });
                setIsLoadingRegistersList(false);
            })
            .execute();
        return responseToDomain;
    }

    const lastRegisters = () => {
        const lastRegisters = excelIntercomsVersionHistoryList(1, 2);
        if (lastRegisters) {
            setLastRegistersList([]);
            setLastRegistersList(lastRegisters);
        }
    }

    const allRegisters = (currentPage: number, maxPerpage: number = 15) => {
        const registers = excelIntercomsVersionHistoryList(currentPage, maxPerpage);
        if (registers) {
            setRegistersList(registers);
        }
    }

    const excelVersionHistoryRestore = () => {
        isLoadingHandler(true);
        new TaskIntercomVersionHistoryRestore({
            setIsLoading: isLoadingHandler,
            setDataTransfer: setDataTransferRestore
        }).execute();
    }

    const showRegistersHandler = () => {
        allRegisters(currentPageRegistersList);
        setShowRegisterList(true);
    }

    const backAllRegistersHandler = () => {
        setShowRegisterList(false);
        setCurrentPageRegistersList(1);
    }

    return {
        lastRegistersList,
        excelVersionHistoryRestore,
        registersList,
        showRegistersHandler,
        showRegistersList,
        allRegisters,
        currentPageRegistersList,
        setCurrentPageRegistersList,
        dataTransferRestore,
        isLoadingRegistersList,
        backAllRegistersHandler,
        currentFileNameRestore,
        setCurrentFileNameRestore,
    }
}