import { useEffect, useState } from "react";
import {getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';

const DashboardAction = () => {

    const [intercoms, setIntercoms] = useState<any>(null)
    const headers = { Authorization: 'Bearer ' + document.cookie.match('(^|;)\\s*' + 'AUTH_TOKEN' + '\\s*=\\s*([^;]+)')?.pop() || '' }

    useEffect(() => {
        if (!intercoms) {
            fetch(getUrlEndpointPrivate("user/dashboard"), { headers })
                .then(response => response.json())
                .then(data => { setIntercoms(data.data); })
        }
    }, [intercoms])


    const hide = (id: string) => {
        const el = document.getElementById(id) as HTMLElement;
        el.style.display = 'none';
    }

    const renderDemoted = () => {
        if (!intercoms) return null
        else return intercoms[Object.keys(intercoms)[1]].map((i: any, index: any) => {
            return (
                <div className="card" id={`card-demoted-${index}`} style={{width: '90%'}}>
                    <div className="card-body dashboard-update">
                        <div>
                            {
                            Object.keys(i).map((k, j) => {
                                if (j !== 2) return <p>{capitalize(k)}: {i[k]}</p>
                                else  return <p>{capitalize(k)}: {i[k]}</p>
                            })
                            }
                        </div>
                        <div>
                            <button className="fas fa-times close" id={`close-demoted-${index}`} onClick={() => hide(`card-demoted-${index}`)}></button>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const renderPromoted = () => {
        if (!intercoms) return null
        else return intercoms[Object.keys(intercoms)[0]].map((i: any, index: any) => {
            return (
                <div className="card" id={`card-promoted-${index}`} style={{width: '90%'}}>
                    <div className="card-body dashboard-update">
                        <div>
                            {
                            Object.keys(i).map((k, j) => {
                                if (j !== 2) return <p>{capitalize(k)}: {i[k]}</p>
                                else  return <p>{capitalize(k)}: {i[k]}</p>
                            })
                            }
                        </div>
                        <div>
                            <button className="fas fa-times close" id={`close-promoted-${index}`} onClick={() => hide(`card-promoted-${index}`)}></button>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const renderCreated = () => {
        if (!intercoms) return null
        else return intercoms[Object.keys(intercoms)[2]].map((i: any, index: any) => {
            return (
                <div className="card" id={`card-created-${index}`} style={{width: '90%'}}>
                    <div className="card-body dashboard-update">
                        <div>
                            {
                            Object.keys(i).map((k, j) => {
                                if (j !== 2) return <p>{capitalize(k)}: {i[k]}</p>
                                else  return <p>{capitalize(k)}: {i[k]}</p>
                            })
                            }
                        </div>
                        <div>
                            <button className="fas fa-times close" id={`close-created-${index}`} onClick={() => hide(`card-created-${index}`)}></button>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const capitalize = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    if (!intercoms) return null

    return (
        <div className="dashboard">
            <div className="dashboard-column">
                <p className="dashboard-title">{capitalize(Object.keys(intercoms)[1])}</p>
                <div className="card dashboard-item">
                    {renderDemoted()}
                </div>
            </div>
            <div className="dashboard-column">
                <p className="dashboard-title">{capitalize(Object.keys(intercoms)[0])}</p>
                <div className="card dashboard-item">
                    {renderPromoted()}
                </div>
            </div>
            <div className="dashboard-column">
                <p className="dashboard-title">{capitalize(Object.keys(intercoms)[2])}</p>
                <div className="card dashboard-item">
                    {renderCreated()}
                </div>
            </div>
        </div>
    )
}

export default DashboardAction