import React, {useEffect, useState} from 'react';
import ImportIntercomsModal from './ImportIntercomsModal';
import {useImportIntercoms} from './hooks/useImportIntercoms';

interface IProps {
    showImportIntercomsModal: boolean,
    setShowImportIntercomsModal: (show: boolean) => void
    disabledRestore: boolean,
}

export const ImportIntercoms = (
    {
        showImportIntercomsModal,
        setShowImportIntercomsModal,
        disabledRestore,
    }: IProps
): React.ReactElement => {
    const {
        isLoading,
        setIsLoading
    } = useImportIntercoms();
    const [pathName] = useState<string>(window.location.pathname);

    useEffect(() => {
        return () => {
            if (pathName !== window.location.pathname) {
                handleCloseModal();
            }
        };
    }, []);

    const handleCloseModal = () => {
        setShowImportIntercomsModal(false);
    }

    return (
        <ImportIntercomsModal
            showModal={showImportIntercomsModal}
            closeHandler={handleCloseModal}
            isLoading={isLoading}
            isLoadingHandler={setIsLoading}
            disabledRestore={disabledRestore}
        />
    );
}