import React from 'react';
import {
    LoadingView,
    Modal,
    SelectorField,
} from '@cuatroochenta/co-admin-react-library';
import {CountResultMessage} from './CountResultMessage';
import {useBulkPromotionModal} from './hooks/useBulkPromotionModal';

interface IProps {
    fieldDefaultValue: number,
    showModal: boolean,
    closeHandler: () => void,
    isLoading: boolean,
    selectedBrandId: number,
    selectedBrandIdHandler: (id: number) => void,
    selectedTechnologyId: number,
    selectedTechnologyIdHandler: (id: number) => void,
    isLoadingHandler: (loading: boolean) => void,
    cancelHandler: () => void,
}

export const BulkPromotionModal = (
    {
        fieldDefaultValue,
        showModal,
        closeHandler,
        isLoading,
        selectedBrandId,
        selectedBrandIdHandler,
        selectedTechnologyId,
        selectedTechnologyIdHandler,
        isLoadingHandler,
        cancelHandler,
    }: IProps
): React.ReactElement => {
    const {
        handleAcceptPromoteModal,
        disabledAcceptBulkPromote,
        brandSelectorFieldValues,
        technologySelectorFieldValues,
        handleSelectedTechnologyId,
        handleSelectedBrandId,
        showCountResult,
        countResult,
    } = useBulkPromotionModal(
        fieldDefaultValue,
        showModal,
        closeHandler,
        isLoading,
        selectedBrandId,
        selectedBrandIdHandler,
        selectedTechnologyId,
        selectedTechnologyIdHandler,
        isLoadingHandler,
        cancelHandler,
    )

    return (
        <Modal
            show={showModal}
            title={'Promocionar intercoms'}
            acceptText={'Aceptar'}
            acceptHandler={handleAcceptPromoteModal}
            onClose={cancelHandler}
            disabledAcceptHandler={disabledAcceptBulkPromote}
        >
            <>
                <LoadingView loading={isLoading}/>
                <div className={"mb-3"}>Selecciona los parámetros que quieres incluir en la selección</div>
                <div className={"form-group form-line"}>
                    <label>Marca</label>
                    <SelectorField
                        value={selectedBrandId.toString()}
                        values={brandSelectorFieldValues}
                        onChange={(id: string) => handleSelectedBrandId(parseInt(id))}
                    />
                </div>
                <div className={"form-group form-line"}>
                    <label>Tecnología</label>
                    <SelectorField
                        value={selectedTechnologyId.toString()}
                        values={technologySelectorFieldValues}
                        onChange={(id: string) => handleSelectedTechnologyId(parseInt(id))}
                    />
                </div>

                {showCountResult &&
                    <div className={"mt-5"}>
                        <CountResultMessage total={countResult.total} compatible={countResult.compatible}/>
                    </div>
                }
            </>
        </Modal>
    )
}