import {BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';
import {ExcelVersionHistoryDTO} from '../dto/ExcelVersionHistoryDTO';

export interface TaskExcelIntercomsVersionHistoryResponse {
    total: number,
    pages: number,
    results: ExcelVersionHistoryDTO[],
}

export default class TaskExcelIntercomsVersionHistory extends BaseGenericRequest<{}, TaskExcelIntercomsVersionHistoryResponse> {

    private readonly data;

    public constructor(page: number, maxPerPage: number) {
        super(METHOD.METHOD_POST, getUrlEndpointPrivate(`intercom/excelintercomsversionhistory/wslist/${page}`));
        this.data = {maxPerPage}
    }

    protected getRequest() {
        return this.data;
    }
}