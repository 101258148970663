import {ExcelVersionHistoryDTO} from '../dto/ExcelVersionHistoryDTO';
import {ExcelVersionHistory} from '../../domain/ExcelVersionHistory';
import {convertStringToDateTime, formatDate, formatTime} from '@cuatroochenta/co-admin-react-library';

export class ExcelVersionHistoryMapper {

    public static toDomain(excelVersionHistoryDTO: ExcelVersionHistoryDTO): ExcelVersionHistory {
        const excelVersionHistory = new ExcelVersionHistory(
            this.excelToDomain(excelVersionHistoryDTO.excel),
            excelVersionHistoryDTO.excel,
            this.dateToDomain(excelVersionHistoryDTO.date),
            this.userNameLastNameToDomain(excelVersionHistoryDTO.user),
            excelVersionHistoryDTO.__permissions.restore,
            excelVersionHistoryDTO.__permissions.download
        )
        return excelVersionHistory;
    }

    private static excelToDomain(excel: string): string {
        if (!excel) {
            return '';
        }
        const splitExcel = excel.split('/');
        return splitExcel[splitExcel.length - 1];
    }

    private static dateToDomain(dateApi: string): string {
        const dateTime = convertStringToDateTime(dateApi);
        return `${formatDate(dateTime)} a las ${formatTime(dateTime)}`;
    }

    private static userNameLastNameToDomain(user: { oid: string, name: string, lastName: string }): string {
        return `${user.name} ${user.lastName}`;
    }
}