import React, {useEffect, useState} from 'react';
import {Icon} from '@cuatroochenta/co-admin-react-library';
import {ExcelValidationMessage} from '../../domain/ExcelValidationMessage';
import './validationMessageCard.scss';
import {EventMessage} from '../../domain/EventMessage';

interface IProps {
    message: ExcelValidationMessage,
    isFirstCard?: boolean,
}

export const ValidationMessageCard = (
    {
        message,
        isFirstCard = false,
    }: IProps
): React.ReactElement => {
    const [cardClasses, setCardClasses] = useState<string>('warning');
    const [iconClass, setIconClass] = useState<string>('fa-solid fa-triangle-exclamation');
    const event = message.event;

    useEffect(() => {
        stateCardClass(event);
    }, []);

    const stateCardClass = (event: EventMessage): void => {
        switch (event) {
            case EventMessage.ERROR:
                setCardClasses('error');
                setIconClass('fa-solid fa-circle-exclamation');
                return;
            case EventMessage.SUCCESS:
                setCardClasses('success');
                setIconClass('fa-solid fa-circle-check');
        }
    };

    return (
        <div className={`validate-card ${isFirstCard ? "first-card" : ""} ${cardClasses}`}>
            <div className={"icon"}>
                <Icon fontAwesomeClass={iconClass}/>
            </div>
            <div className={"content"}>
                <div className={"title"}>
                    {event}
                </div>
                <div className={"message"}>
                    {message.message}
                </div>
            </div>
        </div>
    )
}