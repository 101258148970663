import {useEffect, useState} from "react";
import TaskExcelCurrentVersion, {TaskExcelCurrentVersionResponse} from "../ws/TaskExcelCurrentVersion";
import TaskExportExcelIntercom from "../ws/TaskExportExcelIntercom";

type ExcelCurrentVersionData = {
    currentVersion: string,
    exportHandler: () => void,
}

export const useExcelCurrentVersion = (
    showModal: boolean,
    isLoadingHandler: (loading: boolean) => void,
): ExcelCurrentVersionData => {
    const [currentVersion, setCurrentVersion] = useState<string>('');

    useEffect(() => {
        if (showModal) {
            excelCurrentVersion();
        }
    }, [showModal]);

    const excelCurrentVersion = () => {
        isLoadingHandler(true);
        new TaskExcelCurrentVersion()
            .onSuccess(
                (data: TaskExcelCurrentVersionResponse): void => {
                    setCurrentVersion(data.name);
                    isLoadingHandler(false);
                })
            .onFail(() => isLoadingHandler(false))
            .execute();
    };

    const exportHandler = () => {
        isLoadingHandler(true);
        new TaskExportExcelIntercom()
            .onSuccess(data => {
                isLoadingHandler(false);
                if (data.pdf) {
                    window.open(data.pdf, '_blank');
                }
            })
            .onFail(() => isLoadingHandler(false))
            .execute();
    };

    return {
        currentVersion,
        exportHandler,
    }
}

