import {BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';

export interface RequestData {
    brandId: number,
    technologyId: number
}

interface Response {
    success: boolean,
    error?: string,
}

export default class TaskMultiplePromotionIntercom extends BaseGenericRequest<{}, Response> {

    private readonly data;

    public constructor(data: RequestData) {
        super(METHOD.METHOD_POST, getUrlEndpointPrivate('intercom/multiple-promotion'));
        this.data = data;
    }

    protected getRequest(): RequestData {
        return this.data
    }
}