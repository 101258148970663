import {BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';

export interface RequestData {
    brandId: number,
    technologyId: number
}

export interface TaskMultiplePromotionCountIntercomResponse {
    compatible: number,
    total: number,
}

export default class TaskMultiplePromotionCountIntercom extends BaseGenericRequest<{}, TaskMultiplePromotionCountIntercomResponse> {

    private readonly data;

    public constructor(data: RequestData) {
        super(METHOD.METHOD_POST, getUrlEndpointPrivate('intercom/multiple-promotion/count'));
        this.data = data;
    }

    protected getRequest(): RequestData {
        return this.data
    }
}