import {BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';

export enum STATE_INTERCOM {
    PROMOTE = 'promote',
    DEMOTE = 'demote',
    UPDATE = 'update',
}

interface TaskUpdateIntercomsResponse {
    success: boolean,
    message: string
}

export default class TaskUpdateStateIntercom extends BaseGenericRequest<{}, TaskUpdateIntercomsResponse> {

    private readonly data;

    public constructor(data: any) {
        super(METHOD.METHOD_POST, getUrlEndpointPrivate('sync'));
        this.data = data;
    }

    protected getRequest(): {} {
        return this.data;
    }
}