import {useState} from "react";

type ImportIntercomsData = {
    isLoading: boolean,
    setIsLoading: (loading: boolean) => void,
}

export const useImportIntercoms = (): ImportIntercomsData =>{
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        {
            isLoading,
            setIsLoading
        }
    );
}