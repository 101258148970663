import React, {useState, useEffect} from 'react';
import {
    FormRenderData,
    Module,
    ScreenTypes,
    DropdownItemProps,
    Language,
    IconsBase,
    LabelInfo,
} from '@cuatroochenta/co-admin-react-library';
import '@cuatroochenta/co-admin-react-library/lib/index.css';
import './res/scss/styles.scss'
import DashboardAction from './modules/dashboard/DashboardAction';
import {CustomButtonListEditScreen} from "./modules/intercom/CustomButtonListEditScreen";
import {BulkPromoteIntercoms} from "./modules/intercom/promoteBulkIntercoms/BulkPromoteIntercoms";
import {ImportIntercoms} from "./modules/intercom/importIntercoms/ImportIntercoms";
import TaskExportExcelIntercom, {
    TaskExportExcelIntercomResponse
} from "./modules/intercom/importIntercoms/ws/TaskExportExcelIntercom";
import TaskImportCheckRunningProcess from "./TaskImportCheckRunningProcess";

interface Filters {
    [dynamic: string]: string
}

type DisabledEntityActionsProps = {
    view: boolean,
    edit: boolean,
    create: boolean,
    delete: boolean,
};

export const App = ({}): React.ReactElement => {

    const [showPromoteBulkIntercomsModal, setShowPromoteBulkIntercomsModal] = useState<boolean>(false);
    const [showImportIntercomsModal, setShowImportIntercomsModal] = useState<boolean>(false);
    const [currentEntity, setCurrentEntity] = useState<string>('');
    const [disabledEntityActions, setDisabledEntityActions] =
        useState<DisabledEntityActionsProps>({view: false, edit: false, create: false, delete: false});
    const [importRunningProcess, setImportRunningProcess] = useState<boolean>(false);


    useEffect(() => {
        const handle = setInterval(() => {
            importCheckRunningProcess();
        }, 3000);
        return () => {
            clearInterval(handle);
        };
    }, []);

    useEffect(() => {
        setDisabledActions();
    }, [importRunningProcess, currentEntity]);

    const importCheckRunningProcess = () => {
        if (!currentEntity) {
            return;
        }
        new TaskImportCheckRunningProcess()
            .onSuccess(() => setImportRunningProcess(false))
            .onFail(() => setImportRunningProcess(true))
            .execute();
    };

    const beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    const afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    const afterList = (entity: string): React.ReactElement | undefined => {
        if (entity === 'intercom') {
            return (
                <>
                    <BulkPromoteIntercoms showPromoteBulkIntercomsModal={showPromoteBulkIntercomsModal}
                                          setShowPromoteBulkIntercomsModal={setShowPromoteBulkIntercomsModal}
                    />
                    <ImportIntercoms showImportIntercomsModal={showImportIntercomsModal}
                                     setShowImportIntercomsModal={setShowImportIntercomsModal}
                                     disabledRestore={disabledEntityActions.edit}
                    />
                </>
            );
        }
    };

    const customButtonsList = (entity: string, filters: Filters, orderby: string, order: string): React.ReactNode => {
        return null;
    };

    const initializeDisabledEntityActions = () => {
        setDisabledEntityActions({
            view: false,
            create: false,
            edit: false,
            delete: false,
        })
    };

    const setDisabledActions = () => {
        if (!importRunningProcess) {
            initializeDisabledEntityActions();
            return;
        }
        if (currentEntity === 'intercom') {
            setDisabledEntityActions({
                view: false,
                create: true,
                edit: true,
                delete: true,
            })
        }
        if (currentEntity === 'brand') {
            setDisabledEntityActions({
                ...disabledEntityActions,
                edit: true,
                delete: true,
            })
        }
        if (currentEntity === 'technology') {
            setDisabledEntityActions({
                ...disabledEntityActions,
                edit: true,
                delete: true,
            })
        }
    };

    const customScreenButtonList = (entity: string, type: string, data: any): React.ReactNode => {
        setCurrentEntity(entity);
        if (entity === 'intercom') {
            if (type === ScreenTypes.EDIT) {
                return <CustomButtonListEditScreen intercom={data}/>;
            }
        }
        return null;
    };

    const moreOptionsMenuList = (entity: string): DropdownItemProps[] | null => {
        if (entity === 'intercom') {
            const dropdownItemProps = [];
            dropdownItemProps.push({
                title: 'Promocionar en bloque',
                action: () => setShowPromoteBulkIntercomsModal(true),
            });
            dropdownItemProps.push({
                title: 'Importar Intercoms',
                action: () => setShowImportIntercomsModal(true),
            });
            dropdownItemProps.push({
                title: 'Exportar a fichero',
                action: () => {
                    new TaskExportExcelIntercom()
                        .onSuccess((data: TaskExportExcelIntercomResponse) => window.open(data.pdf, '_blank'))
                        .execute();
                },
            });
            return dropdownItemProps;
        }
        return null;
    };

    const afterLoginButton = (): React.ReactNode => {
        return null;
    };

    const renderLabelInfo = () => {
        if (!disabledEntityActions.create && !disabledEntityActions.edit && !disabledEntityActions.delete) {
            return <></>;
        }
        return (
            <LabelInfo
                iconFontAwesomeClass={IconsBase.WARNING}
                textLabel={'Importación en curso...'}
                titleMessageAlert={'Importación en curso...'}
                messageAlert={'Estamos realizando una actualización en nuestra base de datos. Durante este proceso, el\n' +
                    ' acceso a la plataforma estará temporalmente suspendido. Estimamos que la actualización\n' +
                    ' tomará varios minutos para completarse. Disculpa las molestias.'}
            />
        )
    };

    const languages: Language[] = [
        {name: 'Español', code: 'es'},
        {name: 'English', code: 'en'},
    ];

    return (
        <Module
            actions={[
                {name: 'Dashboard', element: <DashboardAction/>}
            ]}
            beforeFormView={beforeFormView}
            afterFormView={afterFormView}
            customButtonsList={customButtonsList}
            afterLoginButton={afterLoginButton}
            customScreenButtonList={customScreenButtonList}
            locale={'es'}
            languages={languages}
            moreOptionsMenuList={moreOptionsMenuList}
            afterList={afterList}
            disabledActions={{
                entity: currentEntity,
                edit: disabledEntityActions.edit,
                create: disabledEntityActions.create,
                delete: disabledEntityActions.delete,
                view: disabledEntityActions.view,
            }}
            labelInfoCardHeader={renderLabelInfo}
        />
    );
}