import {BaseGenericRequest, getUrlEndpointPrivate} from '@cuatroochenta/co-admin-react-library';
import {METHOD} from '@cuatroochenta/co-generic-request';


export interface TaskExportExcelIntercomResponse {
    pdf?: string,
    error?: string,
}

export default class TaskExportExcelIntercom extends BaseGenericRequest<{}, TaskExportExcelIntercomResponse> {

    public constructor() {
        super(METHOD.METHOD_GET, getUrlEndpointPrivate('intercom/wsexport'));
    }
}